<template>
  <div class="content-wrapper pad">
    <Attachments
      :files="newsroom.files"
      show-filters
    />
  </div>
</template>

<script>
import NewsroomChild from '@/pages/newsroom/NewsroomChild'

export default {
  name: 'NewsroomAssets',

  extends: NewsroomChild,

  data () {
    return {
      pageName: 'Assets'
    }
  }
}
</script>
